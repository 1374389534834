<template>
  <!-- 剧集抽屉 -->
  <a-drawer
    title="生成结算"
    width="700"
    :closable="false"
    :visible="visible"
    @close="onClose"
    :footer-style="{ textAlign: 'right' }"
  >
    <a-form
      ref="RefForm"
      :model="formState"
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 16 }"
    >
      <!-- 团长 -->
      <a-form-item
        label="团长"
        name="ids"
        :rules="[{ required: true, message: '请选择' }]"
      >
        <a-select
          show-search
          mode="multiple"
          v-model:value="formState.ids"
          placeholder="请选择"
          :filter-option="filterOption"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
        >
          <a-select-option
            v-for="item in darens"
            :key="`${item.id}-${item.name}`"
            :value="item.id"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <!-- 日期范围 -->
      <a-form-item
        label="日期范围"
        name="times"
        :rules="[{ required: true, message: '请选择' }]"
      >
        <a-range-picker
          style="width: 100%;"
          v-model:value="formState.times"
          :format="dateFormat"
          :disabledDate="disabledDate"
        />
      </a-form-item>
      <!-- 微小ECPM -->
      <a-form-item
        label="微小ECPM"
        name="wx_cpm_rate"
        :rules="[{ required: true, validator: validatorEcpm }]"
      >
        <a-input
          placeholder="请输入"
          v-model:value="formState.wx_cpm_rate"
        />
      </a-form-item>
      <!-- 抖小ECPM -->
      <a-form-item
        label="抖小ECPM"
        name="dy_cpm_rate"
        :rules="[{ required: true, validator: validatorEcpm }]"
      >
        <a-input
          placeholder="请输入"
          v-model:value="formState.dy_cpm_rate"
        />
      </a-form-item>
    </a-form>
    <!-- 尾部 -->
    <template #footer>
      <a-button style="margin-right: 8px" @click="onClose">取消</a-button>
      <a-button type="primary" @click="touchSubmit">确定</a-button>
    </template>
    <!-- 加载 -->
    <Loading :loading="isLoading"></Loading>
  </a-drawer>
</template>

<script setup>
import { ref, reactive, defineExpose, nextTick } from 'vue'
import { message } from 'ant-design-vue'
import Loading from '@/components/Loading'
import Pub from '@/utils/public'
import dayjs from 'dayjs'
import { darenList, darenSettlement } from '@/api/copyright'

// emit
const emit = defineEmits(['success'])
// Form实例
const RefForm = ref(null)
// 抽屉展示状态
let visible = ref(false)
// 加载
let isLoading = ref(false)
// 日期格式
const dateFormat = 'YYYY-MM-DD'
// id
let id = ref(null)
// 团长列表
let darens = ref([])
// 表单
let formState = reactive({
  // 团长
  ids: [],
  // 日期范围
  times: [],
  start_time: undefined,
  end_time: undefined,
  // 微小ECPM
  wx_cpm_rate: undefined,
  // 抖小ECPM
  dy_cpm_rate: undefined
})

// 模糊匹配
function filterOption (input, option) {
  return `${option.key}`.toLowerCase().includes(`${input || ''}`.toLowerCase())
}

// 可选起始日期2024年9月30号开始，结算日期T-1
function disabledDate (current) {
  const startDate = dayjs('2024-09-30'); // 可选开始日期
  const endDate = dayjs().subtract(1, 'day'); // 当前日期的前一天
  // 禁用不在范围内的日期
  return current < startDate || current > endDate;
}

// 打开抽屉
function showDrawer (params) {
  // 展开
  visible.value = true
  // 赋值
  nextTick(() => {
    // id
    // id.value = params.id
    // 获取选择项列表
    getSelectList()
  })
}

// 获取选择项列表
function getSelectList () {
  darenList().then(res => {
    const { code, data } = res
    if (code === 0) {
      darens.value = data
    }
  })
}

// 关闭抽屉
function onClose () {
  if (!isLoading.value) {
    // 重置
    RefForm.value.resetFields()
    id.value = undefined
    visible.value = false
  }
}

// 效验 - ECPM
function validatorEcpm (rule, value) {
  if (!value) {
    return Promise.reject('请输入')
  } else if (!Pub.REG_IS_NUMBER(value)) {
    return Promise.reject('必须为正数')
  } else if (value > 60) {
    return Promise.reject('取值范围 0~60，最多 2 位小数')
  } else if (Pub.CHECK_NUMBER_DECIMAL(value, 2)) {
    return Promise.reject('最多 2 位小数')
  }
  return Promise.resolve()
}

// 提交
function touchSubmit () {
  RefForm.value.validate().then(() => {
    isLoading.value = true
    const start_time = formState.times[0].format(dateFormat)
    const end_time = formState.times[1].format(dateFormat)
    const params = {
      // id: id.value,
      ...formState,
      start_time,
      end_time
    }
    darenSettlement(params).then(res => {
      isLoading.value = false
      const { code, msg, data } = res
      if (code === 0) {
        message.success('操作成功')
        onClose()
        // 更新父组件
        emit('success')
      } else {
        message.error(res.message || msg)
      }
    })
  })
}

// 暴露出去
defineExpose({
  showDrawer
})

</script>

<style lang="less" scoped>
.a-link {
  margin-left: 8px;
}
.form-item-clear {
  margin-bottom: 0;
}
.form-upload-hint {
  padding-top: 6px;
  color: #8D8D8D;
}
.form-upload-list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
}
.image-view.form-upload-item {
  margin-top: 10px;
  margin-left: 10px;
  position: relative;
  width: 118px;
  height: 148px;
  border-radius: 4px;
  overflow: hidden;
  img {
    display: inline-block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &.horizontal {
    width: 148px;
    height: 118px;
  }
}
.upload-item-select {
  position: absolute;
  right: 8px;
  bottom: 8px;
}
.upload-item-delete {
  position: absolute;
  right: 8px;
  top: 8px;
  font-size: 18px;
  color: #8D8D8D;
  cursor: pointer;
  opacity: 0;
}
.form-upload-item:hover .upload-item-delete {
  opacity: 1;
}
.date-range {
  color: #8D8D8D;
  padding-left: 100px;
  margin-top: -15px;
}
</style>